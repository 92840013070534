import React from "react"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import Link from "next/link"

type Props = {
  centered?: boolean
}

const FooterText: React.FC<Props> = ({centered = false}) => {
	const {t} = useTranslation()
	return (
		<div className={`${centered ? "text-center" : ""}`}>
			<Typography className="mb-2 mb-md-3 d-md-none"
				variant="bodyLgBlack"
				semanticTag="h2"
				style={{fontSize: 20, lineHeight: "32px"}}>{t("Be the first to know about new e-bikes, discounts and brands.")}</Typography>
			{/* <Typography variant="heading3" className="d-none d-md-block" semanticTag="p">{t("Dann melde Dich jetzt für unseren Newsletter an - Erfahre zuerst von unseren Aktionen und neuen E-Bike Marken.")}</Typography> */}
			<Typography className="mb-2 mb-md-3 d-none d-md-block"
				variant="bodyLgBlack"
				semanticTag="h2"
				style={{fontSize: 36, lineHeight: "32px"}}>{t("Mybikeplan AG")}</Typography>
			{/* <Link href={"tel:+41%2043%20505%2013%2018"}>{`${t("Beratung")}: 043 505 13 18`}</Link> */}

			<div className="d-none d-md-block">
				<Link href={"tel:+41%2043%20505%2013%2018"}>
					<a className="text-decoration-none text-white">
						<Typography className="mb-2"
							variant="bodySm">{`${t("consultation")}: 043 505 13 18`}</Typography>
					</a>
				</Link>

				<Link href={"mailto:sales@mybikeplan.ch"}>
					<a className="text-decoration-none text-white">
						<Typography variant="bodySm">sales@mybikeplan.ch</Typography>
					</a>
				</Link>
			</div>

		</div>
	)
}

FooterText.propTypes = {
	centered: PropTypes.bool
}
export default FooterText
