import React, {useContext, useMemo} from "react"
import Link from "next/link"
import FooterText from "../FooterText"
import {useTranslation} from "next-i18next"
import DataContext from "../../../context/DataContext"
import {getMenuTitles, MenuTitlesItemType} from "../../../utility/Helper"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"
import {useRouter} from "next/router"
import {premiumBrands} from "../../../constants"

const FooterContent = () => {
	const {t} = useTranslation()
	const {collections, brands} = useContext(DataContext)
	const router = useRouter()
	const brandsForFooter = brands?.filter(({fields}) => fields?.addToFooter)
	const menuTitles = useMemo(() => getMenuTitles(collections, brandsForFooter), [collections, brandsForFooter])
	return (
		<section className="col-12 col-lg-7 mt-5 pt-5 mt-md-0 pt-md-3">
			<FooterText/>
			<section className="row pt-5 pb-5">
				<article className=" ps-0 col-auto">
					<ul className="list-unstyled">
						<br/><br/>
					</ul>
				</article>
				<article className="col-sm ps-0">
					<ul className="list-unstyled">
						<li className="footer-item ps-2">
							<h4 className="footer-titlelist mb-md-3">{t("Kategorien:")}</h4>
						</li>
						{menuTitles.mobile[0].items?.map(item => {
							const isPremiumBrand = premiumBrands.includes(item?.href?.slice(1))

							return (<li key={item.name}
								className="footer-item mb-md-2">
								{item.name === "Zubehör" || item.name === "Accessoires" ? <Link href={item.href} ><a suppressHydrationWarning
									className="footer-link">
									{item.name}
								</a></Link> :
									item?.type === MenuTitlesItemType.category ?
										<Link href={filterUrl({[urlParams.categories]: item.href?.slice(1)}, urlParams.categories, item.href?.slice(1))} >
											<a suppressHydrationWarning
												className="footer-link rounded">
												{item.name}
											</a>
										</Link> :
										<Link href={isPremiumBrand ? router.locale === "de" ? ` /brand-world/${item.href?.slice(1)}` : ` /fr/brand-world/${item.href?.slice(1)}` : filterUrl({[urlParams.brand]: item.href?.slice(1)}, urlParams.brand, item.href?.slice(1))}>
											<a suppressHydrationWarning
												className="footer-link rounded">
												{item.name}
											</a>
										</Link>}
							</li>)
						}
						)}
					</ul>
				</article>
				<article className="col-4">
					<ul className="list-unstyled col-12 d-flex flex-wrap">
						<li className="footer-item mb-md-2 ps-2 col-12">
							<h4 className="footer-titlelist">{t("Brands: ")}</h4>
						</li>
						<li className="footer-item col-6">
							<Link href={"/alle-marken"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("Alle Marken")}
								</a>
							</Link>
						</li>

						{menuTitles.mobile[1].items?.map(item => {
							const isPremiumBrand = premiumBrands.includes(item?.href?.slice(1))

							return (<li key={item.name}
								className="footer-item mb-md-2 d-inline-block col-6">
								{item?.type === MenuTitlesItemType.category ?
									<Link href={filterUrl({[urlParams.categories]: item.href?.slice(1)}, urlParams.categories, item.href?.slice(1))} >
										<a suppressHydrationWarning
											className="footer-link rounded">
											{item.name}
										</a>
									</Link> :
									<Link href={isPremiumBrand ? router.locale === "de" ? ` /brand-world/${item.href?.slice(1)}` : ` /fr/brand-world/${item.href?.slice(1)}` : filterUrl({[urlParams.brand]: item.href?.slice(1)}, urlParams.brand, item.href?.slice(1))}>
										<a suppressHydrationWarning
											className="footer-link rounded">
											{item.name}
										</a>
									</Link>}
							</li>)
						})

						}
					</ul>
				</article>
				<article className="col-sm">
					<ul className="list-unstyled">
						<li className="footer-item ps-2 mb-md-3">
							<h4 className="footer-titlelist">{t("Links: ")}</h4>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={"/blog"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("Blog")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={"/ueber-uns"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("Über uns")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={"/terms-conditions"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("mybikeplan.ch AGBs")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link passHref={true}
								href={"https://www.swissbilling.ch/wp-content/uploads/swb-agb.pdf"}>
								<a target="_blank"
									className="footer-link">
									{t("Swissbilling")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link passHref={true}
								href={"/agb/AGB_POSP00016512_2024-10-23.pdf"}>
								<a target="_blank"
									className="footer-link">
									{t("MFGroup")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={"/kundendienst"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("Kundendienst")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={"/datenschutz"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("Datenschutz")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={"/referral"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("Referral")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={"/bike-verzeichnis"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("E-Bike Wiki")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={`/faq${t("/konzept")}`}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("FAQ")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={t("/rueckgabe")}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("return")}
								</a>
							</Link>
						</li>
						<li className="footer-item mb-md-2">
							<Link href={"https://join.com/companies/mybikeplan"}>
								<a suppressHydrationWarning
									className="footer-link rounded">
									{t("careers")}
								</a>
							</Link>
						</li>
					</ul>
				</article>
			</section>
		</section>
	)
}

export default FooterContent
