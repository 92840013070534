import React from "react"
import FooterForm from "../FooterForm"
import FooterLabel from "../FooterLabel"
import FooterText from "../FooterText"
import FooterContentMobile from "./FooterContentMobile"
import UspSection from "../../home/UspSection"

const MobileFooter = () => {
	return (
		<footer className="text-white"
			style={{background: "#212529", borderRadius: "24px 24px 0 0"}}>
			<section className="container">

				<div className="row justify-content-betwen align-items-center pt-4">
					<div className="pb-1">
						<UspSection isUspWhite/>
						<div className="mb-4 mt-3"
							style={{height: 1, border: "1px solid rgba(222, 226, 230, 0.48)"}}/>
					</div>
					<FooterText centered={false}/>
					<FooterForm/>
					<FooterContentMobile/>
				</div>
				<FooterLabel/>
			</section>
		</footer>
	)
}

export default MobileFooter
