import React from "react"
import FooterContent from "./FooterContentDesktop"
import FooterForm from "../FooterForm"
import FooterLabel from "../FooterLabel"
import UspSection from "../../home/UspSection"

const DesktopFooter = () => {
	return (
		<footer className="text-white d-none d-md-block"
			style={{background: "#212529", borderRadius: "32px 32px 0 0"}}>
			<section className="container">
				<div className="row align-items-top pt-3">
					<div className="mt-5">
						<UspSection isUspWhite/>
						<div className="my-4"
							style={{height: 1, border: "1px solid rgba(222, 226, 230, 0.48)"}}/>
					</div>
					<FooterContent/>
					<FooterForm/>
				</div>
				<FooterLabel/>
			</section>
		</footer>
	)
}

export default DesktopFooter
